import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Container, Row, Col, Button, Accordion } from "react-bootstrap"
import { Link } from "gatsby-plugin-react-intl"

// Styles
import Title from "../../components/title"
import * as styles from "../../styles/templates/blog.module.scss"
import SignatureServices from "../../components/Signature"

// THIS PAGE IS A SPECIAL EXCEPTION. ALL OTHER SIGNATURE SERVICE PAGES ARE GENERATED FROM GATSBY-NODE

export const query = graphql`
  query {
    contentfulSignatureServices(slug: { eq: "expectant-parents" }) {
      slug
      title
      specialMonitoring
      image {
        gatsbyImageData(quality: 100, width: 400, layout: FULL_WIDTH)
      }
      description {
        raw
      }
    }
    allContentfulLocation {
      edges {
        node {
          location
          slug
        }
      }
    }
  }
`

export default function SignatureService({ data }, props) {
  const Bold = ({ children }) => (
    <span>
      <strong>{children}</strong>
    </span>
  )
  const Text = ({ children }) => <p>{children}</p>

  const options = {
    renderMark: {
      [MARKS.BOLD]: text => <Bold>{text}</Bold>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
      [BLOCKS.EMBEDDED_ASSET]: node => {
        const { gatsbyImageData, description } = node.data.target
        return (
          <div>
            <GatsbyImage image={gatsbyImageData} alt={description} />
          </div>
        )
      },
    },
  }
  const signatureService = data.contentfulSignatureServices
  const locations = data.allContentfulLocation.edges
  return (
    <Layout>
      <Title title={signatureService.title} />
      <SEO title={signatureService.title} />
      <Container fluid className="p-0">
        <Container className={`text-center px-5 ${styles.content}`}>
          <Row xs={1} sm={1} lg={1} xl={1} className="align-items-center">
            <Col className="mx-auto"></Col>
            <Col className="mx-auto text-start ">
              <Row xs={1} sm={1} lg={2} className="align-items-center">
                <Col lg={4}>
                  <GatsbyImage
                    image={signatureService.image.gatsbyImageData}
                    imgStyle={{ borderRadius: "6px" }}
                    alt={signatureService.title}
                  />
                </Col>
                <Col lg={7}>
                  <div className={`${styles.blue} p-5 `}>
                    <h1 className="mt-5 display-1 mb-2">
                      Maybe you’re expecting your first baby..{" "}
                    </h1>
                    <p className="h2">
                      or you’re adding a new sibling to your family tree!
                    </p>
                    <p>
                      Either way, getting ready for a new baby comes with a big
                      to-do list and we are here to help you.
                    </p>
                  </div>
                </Col>
              </Row>

              <div className="mt-5 mb-2">
                <h2>Some Things to Think About</h2>
                <p>
                  If your baby is born in one of our affiliated hospitals, you
                  may have the opportunity to have a KTMG pediatrician visit you
                  there during your stay. That said, wherever your newborn comes
                  into the world— at an affiliated or unaffiliated hospital, a
                  birthing center or at home—we are happy to welcome them to the
                  KTMG family.
                </p>

                <p>
                  <strong>
                    Things to Think About Before Your Baby Is Born:
                  </strong>
                </p>
              </div>
              {/* Accordion Section */}
              <Row className="g-5 align-items-center  mb-5">
                <Col lg={8}>
                  <Accordion className={styles.accordion} cdefaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        1 – 3 Months Before Baby Arrives – Get to Know Us
                      </Accordion.Header>
                      <Accordion.Body>
                        We understand that choosing your baby’s doctor is a big
                        decision. After all, they’ll be a big part of your and
                        your child’s lives for the next 18 years or so! It’s
                        important you find a doctor you connect with and are
                        comfortable with and we want to help with that. That’s
                        why we offer free meet and greet consultations with any
                        of our quality pediatricians. At these consultations,
                        you can meet with our doctors, get to know them, ask any
                        questions, and have the conversations you need to have
                        to find the right doctor for you and your child. Though
                        many parents wait until the third trimester (or even
                        after birth) to begin interviewing pediatricians for
                        their new baby, it’s never too early to get started. If
                        you’re expecting, reach out to us at any time at
                        [emailprotected] or 818-361-5437 and ask for a meet and
                        greet consultation for expectant parents. P.S. We’re
                        happy to offer these same meet and greet consultations
                        if you’re looking for pediatric care for your current
                        family tree too. We’re accepting new patients aged 0 –
                        21.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>
                        1 Month Before Baby Arrives – Your Child’s Car Seat All
                        Set?
                      </Accordion.Header>
                      <Accordion.Body>
                        Did you know that an approved and properly installed
                        infant car seat is required for you to leave the
                        hospital with your newborn? Staff at the hospital will
                        check to ensure that an appropriate car safety seat is
                        properly installed and that you know how to properly
                        place and secure your baby before they will let you take
                        your new bundle of joy home. • Many health plans offer
                        programs that can help you get free or low-cost car
                        seats and may also offer classes on their proper
                        installation and use. • The California Highway Patrol
                        offers car safety seat inspection and education services
                        to help you familiarize yourself with car seat
                        installation and use. You can learn more at this link –{" "}
                        <a href="https://www.chp.ca.gov/programs-services/programs/child-safety-seats">
                          chp.ca.gov/Programs-Services/Programs/Child-Safety-Seats
                        </a>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>
                        When Baby is Delivered – At the Hospital
                      </Accordion.Header>
                      <Accordion.Body>
                        If you are giving birth at a Kids &amp; Teens affiliated
                        hospital, a pediatrician from our practice may be able
                        to see your baby for the first time during your stay at
                        the hospital. Simply tell the hospital when you register
                        at check-in that Kids & Teens Medical Group will be your
                        provider. You can do this even if you haven’t yet chosen
                        which of our pediatricians you want for baby’s ongoing
                        care. If you’re delivering elsewhere—an unaffiliated
                        hospital, birthing center or at home—please call us to
                        schedule a first visit for your newborn as soon as you
                        get home. We recommend that baby’s first appointment
                        with us happen within 24 – 48 hours after you’re
                        discharged from the hospital.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>
                        We Offer Free Meet and Greet Consultations
                      </Accordion.Header>
                      <Accordion.Body>
                        <ul>
                          <li>
                            If you haven’t already selected your baby’s new Kids
                            and Teens Medical Group doctor, we offer free meet
                            and greet consultations with any of our quality
                            pediatricians.
                          </li>
                          <li>
                            At these consultations, you can meet with our
                            doctors, get to know them, ask any questions, and
                            have the conversations you need to have to find the
                            right doctor for you and your child.
                          </li>
                          <li>
                            Simply reach out to us at any time at
                            customerservice@ktdoctor or{" "}
                            <a href="tel:8183615437">818-361-5437</a> and ask
                            for a meet and greet consultation for newborn
                            infants.
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                      <Accordion.Header>
                        Inform Your Health Plan Within 30 Days of Delivery
                      </Accordion.Header>
                      <Accordion.Body>
                        <ul>
                          <li>
                            You’ll want to let your health plan know that you
                            have a new baby within 30 days of delivery.
                          </li>
                          <li>
                            Once baby is born, contact your health plan, so they
                            can set up proper coverage.
                          </li>
                          <li>
                            When you call your health plan, let them know that
                            you’d like to choose Kids &amp; Teens Medical Group
                            as your child’s healthcare provider.
                          </li>
                          <li>
                            If you need any help selecting one of our physicians
                            with your health plan, please call us at:{" "}
                            <a href="tel:8183615437">818-361-5437</a>, or{" "}
                            <Link to="/providers">click-here</Link> to use our
                            physician search.
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                      <Accordion.Header>
                        Inform Your Health Plan Within 30 Days of Delivery
                      </Accordion.Header>
                      <Accordion.Body>
                        You should bring your new baby in to see their KTMG
                        pediatrician within 24 – 48 hours of leaving the
                        hospital after delivery. We recommend that you call{" "}
                        <a href="tel:8183615437">818-361-5437</a> or use our{" "}
                        <Link to="/locations">online-appointment</Link> request
                        to make your first appointment as soon as you get home
                        from the hospital.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="6">
                      <Accordion.Header>
                        Routine Visits and Preventive Care Are Important the
                        First Year
                      </Accordion.Header>
                      <Accordion.Body>
                        Once you’ve selected one of our caring, skilled
                        pediatricians, it’s important to bring your baby to
                        visit us regularly. Routine doctor visits and preventive
                        care, like immunizations, are very important during
                        baby’s first year. The more you connect with our staff
                        and your doctor at KTMG, the better we’ll get to know
                        and care for your family.
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Col>
                <Col lg={3} className={`${styles.hospitals} p-5`}>
                  <h3 className="h1">
                    <strong>KTMG</strong> is proudly affiliated with:
                  </h3>
                  <ul>
                    <li>Northridge Hospital</li>
                    <li>Providence Tarzana Medical Center</li>
                    <li>Valley Presbyterian Hospital</li>
                    <li>Huntington Memorial Hospital</li>
                    <li>Children’s Hospital, Los Angeles</li>
                    <li>Providence Holy Cross Medical Center</li>
                    <li>Cedars-Sinai Medical Center</li>
                    <li>West Hills Hospital</li>
                  </ul>
                  <Link to="/locations">
                    <Button>Find your baby's pediatrician</Button>
                  </Link>
                </Col>
              </Row>

              {signatureService.title === "Free Vaccines" && (
                <p className=" justify-self-end mb-0">
                  <strong>• Qualify for CHDP Services •</strong>
                </p>
              )}

              {signatureService.specialMonitoring && (
                <p className=" justify-self-end mb-0">
                  <strong>• Special Monitoring •</strong>
                </p>
              )}
              <div className={`mt-3`}>
                {renderRichText(signatureService.description, options)}
              </div>

              <p>
                Here at KTMG we make it easy for your newborn to get the
                compassionate health care they need for a healthy start at life.
                If you haven't already selected your baby's KTMG doctor come
                visit us to choose the best pediatrician.
              </p>

              <p>
                You can schedule an appointment online, or call the office
                nearest. After-hours and extended hours through Telehealth are
                also available.
              </p>

              <p>
                Our offices are located in{" "}
                {locations
                  .sort((a, b) => (a.node.location > b.node.location ? 1 : -1))
                  .map(({ node }, idx) => (
                    <Link
                      to={`/locations/${node.slug}`}
                      className={styles.location}
                    >{`${idx === locations.length - 1 ? "and " : ""}${
                      node.location
                    }${idx !== locations.length - 1 ? ", " : "."}`}</Link>
                  ))}
              </p>

              <Link to="/locations">
                <Button className="mt-2">Book an Appointment</Button>
              </Link>
            </Col>
          </Row>
        </Container>
      </Container>
      <hr />
      <SignatureServices page={true} />
    </Layout>
  )
}
